@import "bourbon/bourbon";
@import "neat/neat";
@import "variables";

body, html {
  margin: 0;
  padding: 0;
  background: $whiteColor;
  color: $greyColor;
  font-size: 14px;
  font-weight: 400;
  font-family: $mainFontFamily;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.container {
  @include outer-container;
  margin-bottom: 40px;
}

ul {
  padding: 0;
  list-style: none;
}

ol {
  padding-left: 24px;
}

a {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.column {
  @include outer-container;
}

.row {
  @include row();
}

.col-3 {
  @include span-columns(3);
}

.col-4 {
  @include span-columns(4);
}

.col-8 {
  @include span-columns(8);
  //  @include omega();
}

.col-9 {
  @include span-columns(9);
  //  @include omega();
}

.col-12 {
  @include span-columns(12);
}

@import "header";
@import "footer";
@import "cover";
@import "content";
@import "nav";
@import "icons";